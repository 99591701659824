<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Action Export
      </h5>
    </b-card-header>
    <b-card-body>

      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >

          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="success"               
>
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="text-nowrap"
                @click="$emit('export-excel')"
              >Excel</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="warning"
            >
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="text-nowrap"
                @click="$emit('export-csv')"
              >Csv</span>
            </b-button>
            <b-button
              v-if="false"
              class="ml-2"
              variant="primary"
            >
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="text-nowrap"
                @click="$emit('export-pdf')"
              >Pdf</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BModal, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  setup() {
    return {
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCardText,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {

  },
  methods: {
   
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
