import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRbacList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refRoleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'no', sortable: false },
    { key: 'name', sortable: false },
    { key: 'description', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const moduleArray = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refRoleListTable.value ? refRoleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refRoleListTable.value.refresh()
  }

  const fetchRoles = (ctx, callback) => {
    store
      .dispatch('user/fetchRoles', {
        q: searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
        // sortBy: sortBy.value,
        // sortDesc: isSortDirDesc.value,
        // role: roleFilter.value,
        // plan: planFilter.value,
        // status: statusFilter.value,
      })
      .then(response => {
        const { data: roles, meta } = response.body
        const rolesConvert = roles.map(x => ({
          ...x,
          checked: false
        }))
        console.log(rolesConvert)
        callback(rolesConvert)
        totalUsers.value = meta.itemCount
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching rbac list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchPermissions = async (ctx, callback) => {
    store
      .dispatch('user/fetchPermissions', {
        // q: searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
        // sortBy: sortBy.value,
        // sortDesc: isSortDirDesc.value,
        // role: roleFilter.value,
        // plan: planFilter.value,
        // status: statusFilter.value,
      })
      .then(response => {
        const data = response.body
        data.forEach(element => {
          if (
            !moduleArray.value.filter(e => e.module === element.object)
              .length > 0
          ) {
            const objectModule = {
              module: element.object,
              read: false,
              update: false,
              create: false,
              // manage: {
              //   value: false,
              //   status: false,
              // },
              delete: false,
              deactivate: false,
              approved: false,
            }
            moduleArray.value.push(objectModule)
          }
        })
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching rbac list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  fetchPermissions()
  const timeout = ref(null)
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    showTable.value = true
         clearTimeout(timeout.value)

    timeout.value = setTimeout(() => {
      refetchData()
      showTable.value = false
    }, 2000)
  })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    timeout,
    showTable,
    fetchPermissions,
    fetchRoles,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoleListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    moduleArray,
  }
}
