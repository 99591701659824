<template>
  <div>
    <!-- <action-export
      @export-excel="exportExcel(1)"
      @export-csv="exportExcel(2)"
      @export-pdf="exportExcel(2)"
    /> -->
    <!-- Table Container Card -->

    <b-overlay
      :show="showTable"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  v-if="$can('role_create')"
                  variant="primary"
                  :to="{ name: 'rbac-add' }"
                >
                  <span class="text-nowrap">Add Role </span>
                </b-button>
                <b-button
                  v-if="$can('role_create')"
                  class="ml-2"
                  variant="primary"
                  @click="exportPdf()"
                >
                  <span class="text-nowrap">Export Pdf</span>
                </b-button>
                <b-button
                  v-if="$can('role_create')"
                  class="ml-2"
                  variant="primary"
                  @click="exportExcel(1)"
                >
                  <span class="text-nowrap">Export excel</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-overlay
          :show="showTable"
          rounded="sm"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
        >
          <b-table
            ref="refRoleListTable"
            class="position-relative print-friendly"
            :items="fetchRoles"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column STT -->
            <template #cell(no)="data">
              <b-form-checkbox
                v-model="data.item.checked"
                plain
                class="vs-checkbox-con"
                @change="PushToArray(data.item.checked, data.item.id)"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
              </b-form-checkbox>
            </template>
            <!-- Column: Excute -->
            <template #cell(actions)="data">
              <b-link
                v-if="$can('role_update')"
                :to="{ name: 'rbac-edit', params: { id: data.item.id } }"
              >
                Edit
              </b-link>
              <b-link
                v-if="$can('role_delete')"
                @click="deleteRole(data.item.id)"
              >
                Delete
              </b-link>
            </template>
          </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <vue-html2pdf
      style="display: none"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      filename="export Pdf"
      :pdf-quality="2"
      :preview-modal="true"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="1024px"
      @progress="onProgress($event)"
      ref="html2Pdf"
      :html-to-pdf-options="htmlToPdfOptions"
    >
      <section slot="pdf-content">
        <div v-for="(item, index) in dataRoleExports" :key="index">
          <section class="pdf-item">
            <!-- Field: Role Name -->
            <div style="margin-top: 10px">
              <b-col cols="12" md="8" class="mb-2">
                <div class="d-flex">
                  <div>Role name:</div>
                  <div class="ml-2">
                    {{ item.data.name }}
                  </div>
                </div>
              </b-col>
              <!-- <div style="display: flex;" class="mb-2">
               
              </div> -->
              <b-col cols="12">
                <b-table small :items="item.data.data" style="width: 800px">
                  <template #cell(module)="data">
                    {{ data.value }}
                  </template>
                  <template #cell()="data">
                    <b-form-checkbox
                      :checked="data.value"
                      @change="onCheckModule(data)"
                    />
                  </template>
                </b-table>
              </b-col>
            </div>
          </section>
          <div class="html2pdf__page-break" />
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BOverlay,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// eslint-disable-next-line import/no-cycle
import useRbacList from "@/views/rbac/useRbacList";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import ExcelJS from "exceljs";
import ActionExport from "@/components/userManager/ActionExport.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BFormCheckbox,
    vSelect,
    ActionExport,
    VueHtml2pdf,
    BFormGroup,
  },
  computed: {
    ...mapGetters("app", ["regionNumberOptions"]),
  },
  setup() {
    const toast = useToast();
    const dataFetchPermissions = ref([]);
    const userCheckboxOptions = ref([]);
    const userCheckbox = [];
    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];
    const PushToArray = (value, ...args) => {
      if (value === true) {
        userCheckboxOptions.value.push(args[0]);
      } else {
        userCheckboxOptions.value = userCheckboxOptions.value.filter(
          (item) => item !== args[0]
        );
      }
      console.log(userCheckboxOptions.value);
    };
    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];
    const fetchPermissions = (ctx, callback) => {
      store
        .dispatch("user/fetchPermissions", {
          take: perPage.value,
          page: currentPage.value,
        })
        .then((response) => {
          const data = response.body;
          dataFetchPermissions.value = data.map((x) => ({
            label: x.description,
            value: x.id,
          }));
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching permissions list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const {
      timeout,
      showTable,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      moduleArray,
    } = useRbacList();
    setTimeout(async () => {
      fetchPermissions();
    }, 0);
    return {
      timeout,
      showTable,
      dataFetchPermissions,
      fetchRoles,
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleListTable,
      refetchData,
      // Filter
      avatarText,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      PushToArray,
      userCheckboxOptions,
      userCheckbox,
      moduleArray,
      fetchPermissions,
    };
  },
  data() {
    return {
      htmlToPdfOptions: {
        margin: 2,
      },
      showTable: false,
      dataRoleExports: [],
      currentDataRole: {},
      currentDataPermission: [],
      // roleSelected: [],
      headerExcel: [
        {
          name: "Module",
          key: "module",
        },
        {
          name: "Read",
          key: "read",
        },
        {
          name: "Update",
          key: "update",
        },
        {
          name: "Create",
          key: "create",
        },
        {
          name: "Delete",
          key: "delete",
        },
        {
          name: "Deactivate",
          key: "deactivate",
        },
        {
          name: "Approved",
          key: "approved",
        },
      ],
    };
  },
  methods: {
    onProgress(e) {
      if (e === 100) {
        this.showTable = false;
      }
    },
    async exportPdf() {
      if (this.userCheckboxOptions.length > 0) {
        this.showTable = true;
        const dataRoles = await Promise.all(
          this.userCheckboxOptions.map(async (item, index) => ({
            data: await this.fetchRole(item),
          }))
        );
        this.dataRoleExports = dataRoles.map((x) => ({ ...x }));
        this.$refs.html2Pdf.generatePdf();
      }
    },
    async exportExcel(value) {
      if (this.userCheckboxOptions.length > 0) {
        this.showTable = true;
        const dataRoles = await Promise.all(
          this.userCheckboxOptions.map(async (item, index) => ({
            data: await this.fetchRole(item),
          }))
        );
        const workbook = new ExcelJS.Workbook();
        console.log(dataRoles);
        await dataRoles.forEach(async (x, index) => {
          const sheet = workbook.addWorksheet(`${index + 1}.${x.data.name}`);
          sheet.columns = this.headerExcel.map((x) => ({
            header: `${x.name}`,
            key: x.key,
            width: 15,
          }));
          await x.data.data.forEach(async (i) => {
            await sheet.addRow({
              module: i["module"],
              read: i["read"] === true ? "x" : "",
              update: i["update"] === true ? "x" : "",
              create: i["create"] === true ? "x" : "",
              delete: i["delete"] === true ? "x" : "",
              deactivate: i["deactivate"] === true ? "x" : "",
              approved: i["approved"] === true ? "x" : "",
            });
          });
        });
        var buffer = null;
        var blob = null;
        var filename = "";
        if (value === 1) {
          buffer = await workbook.xlsx.writeBuffer();
          blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          filename = "exported_excel.xlsx";
        } else if (value === 2) {
          buffer = await workbook.csv.writeBuffer();
          blob = new Blob([buffer], { type: "text/csv" });
          filename = "exported_csv.csv";
        }
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        this.showTable = false;
        return dataRoles;
      }
    },
    async fetchRole(id) {
      // await this.fetchPermissions()
      const res = await store.dispatch("authenticate/fetchRole", id);
      // const moduleArrayIndex = [];
      // this.moduleArray.forEach(x =>{
      //   moduleArrayIndex.push(x)
      // })
      const moduleArrayIndex = this.moduleArray.map((x) => ({ ...x }));

      res.body.permissions.forEach((i) => {
        const objIndex = moduleArrayIndex.findIndex(
          (obj) => obj.module === i.object
        );
        moduleArrayIndex[objIndex][`${i.action}`] = true;
      });
      // this.moduleArray.forEach((x, index) => {
      //   this.moduleArray[index].read =  false
      //   this.moduleArray[index].update =  false
      //   this.moduleArray[index].create =  false
      //   this.moduleArray[index].delete =  false
      //   this.moduleArray[index].deactivate =  false
      //   this.moduleArray[index].approved =  false
      // })

      const result = {
        name: res.body.name,
        data: moduleArrayIndex,
      };
      return result;
    },
    showEditRole(data) {
      store
        .dispatch("authenticate/fetchRole", data.id)
        .then((response) => {
          this.currentDataRole = {
            id: response.body.id,
            name: response.body.name,
            description: response.body.description,
            permissions: response.body.permissions.map((x) => ({
              label: x.description,
              value: x.id,
            })),
            region: response.body.roleRegions.map((x) => ({
              label: this.regionNumberOptions.find(
                (e) => e.value === x.regionId
              ).label,
              value: x.regionId,
            })),
          };
          this.currentDataPermission = response.body.permissions.map((x) => ({
            label: x.description,
            value: x.regionId,
          }));
        })
        .catch((error) => {
          // this.$bvtoast({
          //   component: ToastificationContent,
          //   props: {
          //     title: error.response.data.message,
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // })
        });
      this.isEditRoleSidebarActive = true;
    },
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 600);
    },
    deleteRole(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this role.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            store.dispatch("user/deleteRole", { id }).then(() => {
              this.refreshTable();
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
table.print-friendly tr td,
table.print-friendly tr th {
  page-break-inside: avoid;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
